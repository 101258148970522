import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Импорт для перенаправления
import { jwtDecode } from 'jwt-decode';
import { Container, Typography, Grid, Card, CardContent, LinearProgress, List, ListItem, Link, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Button, Avatar } from '@mui/material';import styles from '../styles/StudentDashboard.module.css';
import { getStudentDashboardData } from '../api/progressApi';

const defaultDashboardData = {
    completedLessons: 0,
    completedAssignments: 0,
    learnedQuestions: 0,
    averageLessonDuration: '[недостаточно данных]',
    estimatedCompletionDate: '[недостаточно данных]',
    worstQuestionsToRepeat: ['Нет данных для повторения'],
    questionsForNextLesson: ['Нет данных для следующего занятия'],
    name: 'Student Name' // Дефолтное значение для имени студента
};

const StudentDashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState('educationProgress');
    const navigate = useNavigate();
    const hasFetchedData = useRef(false); // Используем useRef для предотвращения повторных вызовов

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        const token = localStorage.getItem('authToken');

        if (!token) {
            navigate('/login');
        } else {
            // Извлекаем studentId из JWT токена
            const studentId = jwtDecode(token).sub;
            console.log('studentId:', studentId);
            const fetchData = async () => {
                try {
                    const data = await getStudentDashboardData(studentId); // Передаем извлеченный studentId
                    if (data) {
                        setDashboardData(data);
                    } else {
                        setDashboardData(defaultDashboardData);
                    }
                } catch (error) {
                    console.error('Ошибка при получении данных:', error);
                    setDashboardData(defaultDashboardData);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [navigate]);

    // Добавляем функцию для переключения секций
    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    if (loading) {
        return <p>Загрузка...</p>;
    }

    if (!dashboardData) {
        return <p>Нет данных для отображения.</p>;
    }

    return (
        <Container disableGutters
                   maxWidth={false}
                   className={styles.container}>
            <Grid container spacing={3}>
                {/* Левая колонка с навигацией */}
                <Grid item xs={12} md={2}>  {/* Установлено md={2} для 20% ширины */}
                    <Card sx={{ backgroundColor: 'lightslategray', color: '#fff', height: '100%' }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                {dashboardData.name} {/* Имя студента из API */}
                            </Typography>
                            {/* Добавляем аватар под почтой */}
                            <Avatar
                                alt="Student Avatar"
                                src="/static/images/avatar/1.jpg" // Ссылка на аватар пользователя
                                sx={{ width: 80, height: 80, marginTop: 2 }} // Размер и отступы
                            />
                        </CardContent>
                        <List>
                            <ListItem button onClick={() => handleSectionChange('educationProgress')}>
                                <Typography variant="body1" color="inherit"><u>Прогресс обучения</u></Typography>
                            </ListItem>
                            <ListItem button onClick={() => handleSectionChange('employmentProgress')}>
                                <Typography variant="body1" color="inherit"><u>Прогресс трудоустройства</u></Typography>
                            </ListItem>
                            <ListItem button onClick={() => handleSectionChange('resources')}>
                                <Typography variant="body1" color="inherit"><u>Полезные ссылки</u></Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>

                {/* Правая колонка с контентом */}
                <Grid item xs={12} md={10}>  {/* Установлено md={10} для 80% ширины */}
                    {activeSection === 'educationProgress' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Прогресс обучения
                            </Typography>

                            {/* Карточки с прогрессом обучения */}
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Пройденные занятия</Typography>
                                            <Typography variant="body1">{dashboardData.completedLessons} из 27</Typography>
                                            <LinearProgress variant="determinate" value={(dashboardData.completedLessons / 27) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Завершенные практические задания</Typography>
                                            <Typography variant="body1">{dashboardData.completedAssignments} из 10</Typography>
                                            <LinearProgress variant="determinate" value={(dashboardData.completedAssignments / 10) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Выученные вопросы</Typography>
                                            <Typography variant="body1">{dashboardData.learnedQuestions} из 170</Typography>
                                            <LinearProgress variant="determinate" value={(dashboardData.learnedQuestions / 170) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Общий прогресс обучения</Typography>
                                            {/* Процент завершенности */}
                                            <Typography variant="body2" color="textSecondary">
                                                {`${(((dashboardData.learnedQuestions + dashboardData.completedAssignments) / 180) * 100).toFixed(2)}% завершено`}
                                            </Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={((dashboardData.learnedQuestions + dashboardData.completedAssignments) / 180) * 100}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Средняя длительность урока</Typography>
                                            <Typography variant="body3">{dashboardData.averageLessonDuration || '[недостаточно данных]'}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100px' }}>
                                        <CardContent>
                                            <Typography variant="h6">Примерная дата завершения обучения</Typography>
                                            <Typography variant="body1">{dashboardData.estimatedCompletionDate || '[недостаточно данных]'}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>


                            {/* Список топ-10 худших вопросов и вопросы на будущее занятие */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Топ худших вопросов для повторения</Typography>
                                            <List>
                                                {dashboardData.worstQuestionsToRepeat.length > 0 ? (
                                                    dashboardData.worstQuestionsToRepeat.map((question, index) => (
                                                        <ListItem key={index}>
                                                            {index + 1}. {question}
                                                        </ListItem>
                                                    ))
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        [Информация появиться после завершения первого урока]
                                                    </Typography>
                                                )}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Вопросы к следующему занятию</Typography>
                                            <List>
                                                {dashboardData.questionsForNextLesson.map((question, index) => (
                                                    <ListItem key={index}>
                                                        {index + 1}. {question}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {activeSection === 'employmentProgress' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Прогресс трудоустройства
                            </Typography>

                            {/* Карточки с прогрессом трудоустройства */}
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Технические интервью</Typography>
                                            <Typography variant="body1">0 из 10</Typography>
                                            <LinearProgress variant="determinate" value={(0 / 10) * 100} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            {/* Таблица с откликами на вакансии */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Список откликов на вакансии</Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Компания</TableCell>
                                                    <TableCell>Дата отклика</TableCell>
                                                    <TableCell>Статус</TableCell>
                                                    <TableCell>Примечания</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Компания А</TableCell>
                                                    <TableCell>10/10/2024</TableCell>
                                                    <TableCell>В ожидании</TableCell>
                                                    <TableCell>—</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {activeSection === 'resources' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Полезные ссылки
                            </Typography>

                            {/* Таблица с полезными ресурсами */}
                            <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Полезные ресурсы</Typography>
                                            <List>
                                                <ListItem>
                                                    <Link href="https://example.com" target="_blank">Полезный ресурс 1</Link>
                                                </ListItem>
                                                <ListItem>
                                                    <Link href="https://example.com" target="_blank">Полезный ресурс 2</Link>
                                                </ListItem>
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default StudentDashboard;
