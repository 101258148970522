// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#007bff',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: '#0056b3',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '15px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h4: {
                    fontSize: '2rem',
                    marginBottom: '20px',
                },
                body2: {
                    fontSize: '1rem',
                    color: '#555',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: 'none', // Отключаем ограничение ширины
                    padding: '24px',
                    backgroundColor: null,
                },
            },
        },
        // MuiCard: {
        //     styleOverrides: {
        //         root: {
        //             height: '100px', // Ширина карточки
        //
        //         },
        //     },
        // },
    },
});

export default theme;
