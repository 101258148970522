// registerApi.js
export const register = async (requestData) => {
    try {
        const response = await fetch('http://localhost:8080/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-application-name': 'web',
            },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (response.ok) {
            return data; // Возвращаем успешный результат
        } else {
            throw new Error(data.error || 'Ошибка регистрации');
        }
    } catch (error) {
        throw new Error('Ошибка подключения к серверу');
    }
};


