import React, { useState, useEffect } from 'react';
import { Container, Box } from '@mui/material'; // Импорт компонентов из Material UI
import LoginPage from './components/LoginPage'; // Импорт формы авторизации
import RegisterPage from './components/RegisterPage'; // Импорт страницы регистрации
import StudentDashboard from './components/StudentDashboard'; // Импорт дашборда для студентов
import QuestionsManager from './components/QuestionsManager';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Импорт маршрутизации
import { ThemeProvider } from '@mui/material/styles'; // Импорт ThemeProvider для темы
import theme from './theme'; // Импорт пользовательской темы

function HomePage() {
    const navigate = useNavigate();

    useEffect(() => {
        // Проверка авторизации пользователя
        const token = localStorage.getItem('authToken') || document.cookie.includes('authToken');

        if (token) {
            // Если пользователь авторизован, перенаправляем на дашборд
            navigate('/dashboard');
        } else {
            // Если не авторизован, перенаправляем на страницу регистрации
            navigate('/register');
        }
    }, [navigate]);

    return (
        <Box>
            <p>Проверка авторизации...</p>
        </Box>
    );
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Container maxWidth={false}>
                    <Box>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/questions" element={<QuestionsManager />} />
                            <Route path="/register" element={<RegisterPage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/dashboard" element={<StudentDashboard />} />
                        </Routes>
                    </Box>
                </Container>
            </Router>
        </ThemeProvider>
    );
}

export default App;
