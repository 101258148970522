import React, { useState } from 'react';
import { TextField, Button, Container, Grid, Typography } from '@mui/material';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom'; // Хук для перенаправления
import { register } from '../api/registerApi'; // Импортируем API для регистрации
import signUpImage from '../assets/img/Sign_up2.png';  // Убедитесь, что путь корректен
import styles from '../styles/RegisterPage.module.css';  // Импорт стилей

const RegisterPage = () => {
    const [login, setLogin] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [education, setEducation] = useState('');
    const [city, setCity] = useState('');
    const [age, setAge] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [inviteKey, setInviteKey] = useState(''); // Поле для пригласительного ключа
    const [error, setError] = useState(''); // Ошибка, если она есть

    const navigate = useNavigate(); // Хук для навигации

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Очистка предыдущих данных
        localStorage.removeItem('authToken');  // Удаление предыдущего токена
        console.log('Предыдущий токен удален.');

        const requestData = {
            phoneNumber: login.replace(/\D/g, ''), // Очищаем номер телефона от лишних символов
            name,
            lastName,
            education,
            city,
            age: parseInt(age, 10),
            password,
            email,
            invitedKey: inviteKey, // Обратите внимание на правильное название поля
        };

        try {
            const data = await register(requestData); // Вызов API регистрации

            // Логируем полученные данные
            console.log('Ответ сервера:', data);

            // Проверяем, есть ли токен в ответе
            if (data.accessToken) {
                // Сохраняем новый токен
                localStorage.setItem('authToken', data.accessToken);
                console.log('Новый токен сохранен:', data.accessToken);

                // Перенаправляем на дашборд
                navigate('/dashboard');
            } else {
                throw new Error('Не удалось получить токен после регистрации.');
            }
        } catch (error) {
            setError(error.message); // Устанавливаем ошибку
            console.error('Ошибка регистрации:', error.message); // Логируем ошибку
        }
    };

    return (
        <Container className={styles.container}>
            <Typography variant="h6" className={styles.title}>Важная информация:</Typography>
            {error && (
                <Typography color="error" align="center" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}
            <Typography variant="h7" className={styles.regDescription}>
                Это закрытая платформа для обучения.<br />
                Для регистрации Вам необходимо связаться с администратором через телеграм -
                {' '}
                <a href="https://t.me/Max_Alexeenko" target="_blank" rel="noopener noreferrer">
                    @Max_Alexeenko</a>
                {' '}
                <br />
                и запросить ключ приглашения с помощью которого можно пройти регистрацию.
            </Typography>
            <Grid container spacing={2} className={styles.gridContainer}>
                {/* Левая колонка */}
                <Grid item xs={12} md={6} className={styles.leftColumn}>
                    <Typography variant="h4" className={styles.title}><br />Регистрация нового ученика</Typography>
                    <Typography variant="body2" className={styles.description}>
                        Обратите внимание! На основе данных указанных при регистрации будет сгенерировано резюме которое увидят работодатели.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputMask
                                    mask="+7 (999) 999-99-99"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                >
                                    {() => (
                                        <TextField
                                            label="Телефон"
                                            fullWidth
                                            required
                                            className={styles.formControl}
                                        />
                                    )}
                                </InputMask>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Город проживания"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Имя"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Образование (например: Высшее / ДГТУ 2005)"
                                    value={education}
                                    onChange={(e) => setEducation(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Фамилия"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Возраст"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    fullWidth
                                    type="number"
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Пароль"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>

                            {/* Поле для пригласительного ключа */}
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Пригласительный ключ"
                                    value={inviteKey}
                                    onChange={(e) => setInviteKey(e.target.value)}
                                    fullWidth
                                    required
                                    className={styles.formControl}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            className={styles.button}
                        >
                            Зарегистрироваться
                        </Button>
                    </form>
                </Grid>

                {/* Правая колонка с изображением */}
                <Grid item xs={12} md={6} className={styles.rightColumn}>
                    <img src={signUpImage} alt="Sign Up Illustration" style={{ width: '80%', borderRadius: '8px' }} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default RegisterPage;
