import React, { useState } from 'react';
import { TextField, Button, Container, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/authApi'; // Импортируем функцию логина

// Регулярное выражение для валидации email
const emailPattern = /^(?=.{6,64}$)([a-zA-Z\d!#$%&'*+/=?^_`{|}~])(\.?[a-zA-Z\d!#$%&'*+/=?^_`{|}~-])*@([a-zA-Z\d](-*?[a-zA-Z\d])*\.)+[a-zA-Z]{2,}$/;

const LoginPage = () => {
    const [loginValue, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [touchedFields, setTouchedFields] = useState({
        login: false,
        password: false,
    });
    const [error, setError] = useState(''); // Для отображения ошибок
    const navigate = useNavigate(); // Используется для перенаправления

    // Проверка, валидно ли поле "Телефон или почта"
    const isLoginValid = () => {
        const phoneFilled = loginValue.replace(/\D/g, '').length === 11;
        const isEmailValid = emailPattern.test(loginValue);
        return phoneFilled || isEmailValid;
    };

    // Проверка, валидна ли форма
    const isFormValid = () => {
        return isLoginValid() && password.length >= 6 && password.length <= 30;
    };

    // Переключение отображения пароля
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Предотвращение действия по умолчанию
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Обработка потери фокуса на полях
    const handleBlur = (field) => {
        setTouchedFields({ ...touchedFields, [field]: true });
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await login(loginValue, password); // Используем функцию логина из authApi.js
            localStorage.setItem('authToken', data.accessToken); // Сохраняем токен
            console.log('Токен сохранен:', data.accessToken); // Логируем сохранение токена
            navigate('/dashboard'); // Перенаправляем пользователя на дашборд
            console.log('Перенаправление на дашборд'); // Логируем перенаправление
        } catch (err) {
            setError(err.message); // Устанавливаем ошибку в случае неудачи
            console.error('Ошибка авторизации:', err.message); // Логируем ошибку
        }
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h5" align="center" sx={{ mb: 4 }}>Вход в личный кабинет ученика</Typography>

            {error && (
                <Typography color="error" align="center" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}

            <form onSubmit={handleSubmit}>
                {/* Поле "Телефон или почта" */}
                <TextField
                    label="Телефон или почта"
                    value={loginValue}
                    onChange={(e) => setLogin(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    error={touchedFields.login && !isLoginValid()}
                    helperText={
                        touchedFields.login && !isLoginValid()
                            ? 'Введите корректный номер телефона или email'
                            : ''
                    }
                    onBlur={() => handleBlur('login')}
                />

                {/* Поле пароля с возможностью показать/скрыть */}
                <TextField
                    label="Пароль"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    inputProps={{ minLength: 6, maxLength: 30 }}
                    error={touchedFields.password && (password.length < 6 || password.length > 30)}
                    helperText={
                        touchedFields.password && (password.length < 6 || password.length > 30)
                            ? 'Пароль должен содержать от 6 до 30 символов'
                            : ''
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onBlur={() => handleBlur('password')}
                />

                {/* Кнопка авторизации */}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isFormValid()}
                    sx={{
                        backgroundColor: isFormValid() ? '#007bff' : '#ccc',
                        ':hover': {
                            backgroundColor: isFormValid() ? '#0056b3' : '#ccc',
                        },
                        fontSize: '16px',
                        padding: '10px',
                        borderRadius: '5px',
                    }}
                >
                    Войти
                </Button>
            </form>
        </Container>
    );
};

export default LoginPage;
